<!-- 分时段用电 -->
<template>
  <div id="day-parting">
    <div class="tree">
      <div class="tree-title">组织结构</div>
      <el-input placeholder="输入检索内容" suffix-icon="el-icon-search" v-model.trim="filterText"></el-input>
      <el-tree ref="tree" v-loading="loading" :default-expand-all="false" :show-checkbox="false" :data="treeData"
               :check-on-click-node="false" :props="defaultProps" node-key="id" :filter-node-method="filterNode"
               :default-expanded-keys="treeChildren"
               @node-click="handleCheck"></el-tree>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>分时段用电</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData">
            <el-form-item label="日期:" class="query-date">
              <el-date-picker :append-to-body="false" placeholder="请选择日期" value-format="yyyy-MM-dd" type="date"
                v-model="formData.time"></el-date-picker>
            </el-form-item>
            <el-form-item label="配电室" label-width="50px">
              <el-select filterable v-model="formData.stationNumber" :popper-append-to-body="false" clearable
                         placeholder="请选择场站名称">
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                           :value="item.stationNumber"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="回路" label-width="50px">
<!--              <el-select v-model="valueH" filterable :popper-append-to-body="false" clearable placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in optionsH"-->
<!--                    :key="item.value"-->
<!--                    :label="item.label"-->
<!--                    :value="item.value">-->
<!--                </el-option>-->
<!--              </el-select>-->
              <el-input v-model.trim="formData.loopName" placeholder="请输入回路名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="searchList">搜索</el-button>
              <el-button type="primary" @click="getExport">导出</el-button>
              <!-- <el-button type="primary" icon="el-icon-search">图表</el-button> -->
              <!-- <el-button type="primary" icon="el-icon-download">导出</el-button> -->
              <!-- <span class="tip">(*为进线回路)</span> -->
            </el-form-item>


          </el-form>
        </div>
        <el-table class="table" :data="tableData" @selection-change="handleSelectionChange">
           <el-table-column type="selection" width="50"> </el-table-column>
          <el-table-column align="center" min-width="200" prop="loopName" label="回路名称/kw·h">
          </el-table-column>
          <el-table-column align="center" label="尖">
            <el-table-column align="center" prop="one" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.tipPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="two" label="单价" width="120">
              <template slot-scope="scope">
                 <span style="color: rgb(19, 206, 102);">{{ scope.row.tipPowerPrice }}</span>
<!--                <span style="color: rgb(19, 206, 102);">0</span>-->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="three" label="金额" width="120">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.tipPowerPriceTotal, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="峰">
            <el-table-column align="center" prop="four" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.peakPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="five" label="单价" width="120">
              <template slot-scope="scope">
                 <span style="color: rgb(19, 206, 102);">{{ scope.row.peakPowerPrice }}</span>
<!--                <span style="color: rgb(19, 206, 102);">0</span>-->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="six" label="金额" width="120">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.peakPowerPriceTotal, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="平">
            <el-table-column align="center" prop="seven" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.flatPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="eight" label="单价" width="120">
              <template slot-scope="scope">
                 <span style="color: rgb(19, 206, 102);">{{ scope.row.flatPowerPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="nine" label="金额" width="120">
              <template slot-scope="scope">
                 <span style="color: rgb(162, 120, 7);">{{ scope.row.flatPowerPriceTotal.toFixed(2) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="谷">
            <el-table-column align="center" prop="ten" label="电量" min-width="150">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.valleyPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="eleven" label="单价" width="120">
              <template slot-scope="scope">
                 <span style="color: rgb(19, 206, 102);">{{ scope.row.valleyPowerPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="twelve" label="金额" width="120">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.valleyPowerPriceTotal, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="合计">
            <el-table-column align="center" min-width="150" prop="quantity" label="电量/kw·h">
              <template slot-scope="scope">
                <span style="color: white;">{{ truncateNumber(scope.row.totalPower, 2) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="200" prop="total" label="金额(元)">
              <template slot-scope="scope">
                <span style="color: rgb(162, 120, 7);">{{ truncateNumber(scope.row.totalPrice, 4) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
import {deviceApi} from "@/api/device";
import {energyStationApi} from "@/api/energyStation";
import moment from "moment";
import download from "@/utils/download";
// import { time } from 'echarts';
export default {
  name: "",

  data() {
    return {
      treeChildren: [],
      loading: false,
      defaultProps: {
        children: "companyInfoList",
        id: "id",
        label: "companyName",
      },
      filterText: '',
      treeData: [],
      checkedNodes: [],
      valueP: '',
      optionsP: [
        {
          value: 'ZD0001',
          label: 'ZD0001'
        }, {
          value: 'ZD0002',
          label: 'ZD0002'
        }
      ],
      valueH: '',
      optionsH: [],
      tableSelectData: [],
      stationData: [],
      formData: {
        time: "",
        loopName: '',
        stationNumber: '',
        companyInfoId: '',
      },
      tableData: [],
      select: 0,
      loopIds: []
    };
  },
  created() {
    this.$store.commit('increment', '用电分析')
    this.$store.commit('selectChild', '分时段用电')
    this.$store.commit("selectChildren", "");
    this.formData.time = moment().format('YYYY-MM-DD');
  },

  mounted() {
    this.queryUnitName()
  },


  methods: {
    getExport() {
      if (this.tableSelectData.length == 0) {
        this.$message({
          message: '请至少选择一套数据',
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });
        return
      }
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      let obj = {
        stationNumber: this.formData.stationNumber,
        time: this.formData.time,
        loopName: this.formData.loopName,
        companyInfoId:this.formData.companyInfoId,
        loopIds: this.loopIds.join(',')
      }
      if (!this.formData.time) {
        obj.time = `${year}-${month}-${day}`
      }
      emissionsCarbonApi.periodExport(obj).then(res => {
        let name = '分时段用电' + moment().format('YYYY-MM-DD')
        download.excel(res,name)
        this.$message({
          message: '导出成功',
          type: 'success',
          duration: 3000,
          customClass: "messageText",
        });
      })
    },
    queryStation(companyId) {
      this.stationData = []
      energyStationApi.queryStation({companyId: companyId}).then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    queryUnitName() {
      this.loading = true;
      deviceApi.queryUnitName().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.treeData = res.data;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeData[0].companyInfoList[0].id); // 默认选中节点第一个
            this.treeChildren = [this.treeData[0].id]
            this.formData.companyInfoId = this.treeData[0].companyInfoList[0].id
            this.getPeriodList();
            this.queryStation(this.formData.companyInfoId)
          });
        }
      });
    },
    handleCheck(checkedNodes, event) {
      this.formData.stationNumber = ''
      if (this.checkedNodes.id == checkedNodes.id) {
        this.checkedNodes = ''
        this.formData.companyInfoId = checkedNodes.id
        this.getPeriodList()
        this.queryStation(this.formData.companyInfoId)
      } else {
        this.formData.companyInfoId = checkedNodes.id
        this.checkedNodes = checkedNodes;
        this.getPeriodList()
        this.queryStation(this.formData.companyInfoId)
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    selectChange(val) {
      console.log(val)
      this.valueH = ''
      this.optionsH = []
      if (val == '1') {
        this.optionsH = [
          {value: '1', label: '真的'},
          {value: '2', label: '假的'}
        ]
      } else if (val == '2') {
        this.optionsH = [
          {value: '1', label: '不是真的'},
          {value: '2', label: '不是假的'}
        ]
      }
    },
    handleSelectionChange(val) {
      this.loopIds = []
      this.tableSelectData = []
      this.tableSelectData = val;
      this.tableSelectData.forEach(item => {
        this.loopIds.push(item.loopId);
      })
    },

    searchList(){
      this.getPeriodList()
    },
    truncateNumber(value, decimalPlaces) {
      value = parseFloat(value);
      // 检查转换后的值是否为有效数字
      if (isNaN(value)) return '';
      if (value === 0) return '0';
      // 将数值转换为字符串，然后根据指定位数保留小数
      if (decimalPlaces === 4) {
        return 0
        // return value.toFixed(4);
      } else if (decimalPlaces === 2) {
        return value.toFixed(2);
      } else {
        return value.toString().slice(0, value.toString().indexOf('.') + 5);
      }
    },
    getPeriodList() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      let obj = {
        stationNumber: this.formData.stationNumber,
        time: this.formData.time,
        loopName: this.formData.loopName,
        companyInfoId:this.formData.companyInfoId
      }
      if (!this.formData.time) {
        obj.time = `${year}-${month}-${day}`
      }
      emissionsCarbonApi.getPeriodListT(obj).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
        }
      });
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#day-parting {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 220px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  //padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus>.el-tree-node__content {
  background-color: #03183f;
}

.main {
  min-width: 1280px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.query-form :deep() .el-form {
  height: 40px;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0;
  margin-right: 8px;
}

/* .form :deep().el-date-editor {
  width: 132px;
} */

.hour :deep() .el-select {
  width: 56px;
  padding: 0;
}

.hour span {
  color: #aed6ff;
  margin: 0 5px 0 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  height: 40px;
  width: 100%;
}


.tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 10px;
}

/* 表格 */

/* 设置表头的背景颜色 */
.table :deep() thead.is-group th.el-table__cell {
  background: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() .el-table__cell {
  padding: 4px 0;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep .el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid rgb(19, 43, 83) !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 滚动条样式 */

/* 更改 el-table 横向滚动条的样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-table__body-wrapper {
  height: 72vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
